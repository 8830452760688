<template>
    <div id="demo"> <!-- grid, place-items: center -->
        <div id="back-link">
            <span>&laquo;</span> <a href="https://plint-demos.nl" title="Back to PLint's Playground">PLint's Playground</a>
        </div>
        <div id="phone">
            <div id="container">
                <div id="image-grid"> <!-- grid of ingredient images -->
                    <img alt="ingredient" src="../assets/small/2.jpg">
                    <img alt="ingredient" src="../assets/small/3.jpg">
                    <img alt="ingredient" src="../assets/small/10.jpg">
                    <img alt="ingredient" src="../assets/small/17.jpg">
                    <img alt="ingredient" src="../assets/small/19.jpg">
                    <img alt="ingredient" src="../assets/small/22.jpg">
                    <img alt="ingredient" src="../assets/small/23.jpg">
                    <img alt="ingredient" src="../assets/small/25.jpg">
                    <img alt="ingredient" src="../assets/small/26.jpg">                
                    <img alt="ingredient" src="../assets/small/29.jpg">
                    <img alt="ingredient" src="../assets/small/31.jpg">
                    <img alt="ingredient" src="../assets/small/33.jpg">
                    <img alt="ingredient" src="../assets/small/35.jpg">
                    <img alt="ingredient" src="../assets/small/41.jpg">
                    <img alt="ingredient" src="../assets/small/42.jpg">
                    <img alt="ingredient" src="../assets/small/49.jpg">
                    <img alt="ingredient" src="../assets/small/50.jpg">
                    <img alt="ingredient" src="../assets/small/52.jpg">
                    <img alt="ingredient" src="../assets/small/57.jpg">
                    <img alt="ingredient" src="../assets/small/61.jpg">
                    <img alt="ingredient" src="../assets/small/72.jpg">
                    <img alt="ingredient" src="../assets/small/74.jpg">
                    <img alt="ingredient" src="../assets/small/76.jpg">
                    <img alt="ingredient" src="../assets/small/78.jpg">
                    <img alt="ingredient" src="../assets/small/79.jpg">
                    <img alt="ingredient" src="../assets/small/87.jpg">
                    <img alt="ingredient" src="../assets/small/88.jpg">
                    <img alt="ingredient" src="../assets/small/89.jpg">
                    <img alt="ingredient" src="../assets/small/91.jpg">
                    <img alt="ingredient" src="../assets/small/100.jpg">
                    <img alt="ingredient" src="../assets/small/102.jpg">
                    <img alt="ingredient" src="../assets/small/103.jpg">
                    <img alt="ingredient" src="../assets/small/105.jpg">
                    <img alt="ingredient" src="../assets/small/107.jpg">
                    <img alt="ingredient" src="../assets/small/109.jpg">
                    <img alt="ingredient" src="../assets/small/110.jpg">
                    <img alt="ingredient" src="../assets/small/112.jpg">
                    <img alt="ingredient" src="../assets/small/113.jpg">
                    <img alt="ingredient" src="../assets/small/129.jpg">
                    <img alt="ingredient" src="../assets/small/139.jpg">
                    <img alt="ingredient" src="../assets/small/141.jpg">
                    <img alt="ingredient" src="../assets/small/144.jpg">
                    <img alt="ingredient" src="../assets/small/146.jpg">
                    <img alt="ingredient" src="../assets/small/157.jpg">
                </div>

                <div id="overlay"></div>  <!-- overlay with title, intro and button -->

                <div id="action-block" :class="randomRecipe ? `dynamic` : ``">
                    <!-- 3 options: before, during and after shuffle -->

                    <transition name="fade">
                        <div v-if="randomRecipe" class="after-shuffle">
                            <h2 v-text="randomRecipe.name"></h2>
                            <img :src="randomRecipe.recipe_image" :alt="randomRecipe.name">
                            <a :href="`https://solo-cooking.nl/recepten/${randomRecipe.slug}`" class="btn">Bekijk het recept</a>
                            <button class="link" @click="reset">Liever iets anders? Probeer opnieuw!</button>
                        </div>
                    </transition>

                    <div v-if="showCounter" class="counter">
                        <div>{{ counterValue }}</div>
                    </div>

                    <div v-if="!randomRecipe && !showCounter" class="before-shuffle">
                        <h1 class="title">Recepio!</h1>
                        <p>Vind een heerlijk recept voor vandaag: druk op de knop en laat je verrassen!</p>
                        <button type="button" @click="shuffle">Geef me een recept</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const API_BASE_URL = `${process.env.VUE_APP_API_PATH}/recipes/demo`
export default {
    name: 'DemoCounter',
    data() {
        return {
            showCounter: false,
            counterValue: 3,
            randomRecipe: null,
        }
    },
    methods: {
        async shuffle() {
            const apiUrl = `${API_BASE_URL}/1`; // pick a single random recipe
            const randomRecipe = await axios.get(apiUrl)

            this.showCounter = true;

            const counterInterval = setInterval(() => {
                this.counterValue -= 1
            }, 500)

            setTimeout(() => {
                this.showCounter = false
                this.randomRecipe = randomRecipe.data.data.pop()
                clearInterval(counterInterval)
                this.counterValue = 3
            }, 1500)
        },
        reset() {
            this.randomRecipe = null
            this.shuffle()
        },
    },
}
</script>

<style lang="less" scoped>
@yellow: #e6ae48;
@backgroundyellow: #f8efc4f2;
@brown: #78190d;
@bodytext: #333333;

.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#demo {
    display: grid;
    place-items: center;
    height: 100vh;
    grid-template-rows: 1fr 50px;

    #back-link {
        grid-row-start: 2;
        border-top: 1px solid @brown;
        width: 100%;
        height: 50px;
        line-height: 50px;

        a, span {
            color: @brown;
            font-weight: bold;
        }
    }

    #container {
        width: 100%;
        height: 100%;
        background: #efefef;
        position: relative;
        overflow-y: hidden;

        #image-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            position: relative;

            img {
                width: 100%;
                height: calc((100vh - 50px)/11);
                object-fit: cover;
            }
        }

        #overlay {
            background: #333;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.2;
            height: 100%;
            z-index: 1;
        }

        #action-block {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 376px;
            min-height: 376px;
            overflow-y: hidden;
            transition:max-height 0.8s ease-in;
            width: 290px;
            padding: 25px;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            background: @backgroundyellow;
            color: @brown;
            border-radius: 4px;
            border: 1px solid @brown;
            box-shadow: 0 3px 1px -2px @brown, 0 2px 2px 0 @brown, 0 1px 5px 0 @brown; 
            display: grid;
            align-items: center;           

            &.dynamic {
                max-height: 450px;
            }

            h1, h2 {
                margin-top: 0;
            }

            h2 {
                line-height: 28px;
            }

            p {
                color: @bodytext;
            }

            button, .btn {
                margin-top: 10px;
                background: @brown;
                color: #fff;
                height: 44px;
                border: none;
                padding-left: 15px;
                padding-right: 15px;
                font-weight: 700;
                text-transform: uppercase;
            }

            img {
                width: 100%;
            }

            a.btn {
                text-decoration: none;
                display: block;
                line-height: 44px;
            }

            button.link {
                padding: 0;
                background: none;
                color: @yellow;
                border: none;
                text-decoration: underline;
                text-transform: none;
                height: auto;
                margin-top: 15px;
            }

            .counter {
                min-height: 200px;
                display: grid;
                place-items: center;

                div {
                    color: #e6ae48;
                    font: 800 40px system-ui;
                    font-size: 150px;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    #demo {
        background-image: url(../assets/table-vegetable.jpg);
        background-size: cover;
        background-position: 50% 50%;
        grid-template-rows: 50px 1fr;

        #back-link {
            grid-row-start: 1;
            border-top: none;
            background: #78190d4d;

            a, span {
                color: black;
            }
        }

        #phone {
            background-image: url(../assets/smartphone-350.png);
            width: 350px;
            height: 703px;
            background-size: 100%;
        }

        #container {
            max-width: 323px;
            max-height: 573px;
            margin-left: 12px;
            margin-top: 68px;

            #image-grid {
                img {
                    height: 52.1px;
                }
            }
        }
    }
}

/* Voor landscape mobile devices */
@media (min-width: 600px) and (max-height: 703px) {
    #demo {
        height: auto;
        padding: 10px 0;
    }
}
</style>